
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import cv_img from '../../../assets/images/cv.svg';
import star_img from '../../../assets/images/star-outline.svg';

const MatchingTable = ({ motion, axes, matchings }) => {

    const { t } = useTranslation();

    /**
     * Page state
     */

    const navigate = useNavigate();

    const [xlabel, setXLabel] = useState('');
    const [ylabel, setYLabel] = useState('');
    const [zlabel, setZLabel] = useState('');

    /**
     * Page lifecycle
     */

    useEffect(() => {

        const xaxis = motion.categories?.find(w => w.id === axes.x);
        const yaxis = motion.categories?.find(w => w.id === axes.y);
        const zaxis = motion.categories?.find(w => w.id === axes.z);

        setXLabel(xaxis?.label ?? 'n/a');
        setYLabel(yaxis?.label ?? 'n/a');
        setZLabel(zaxis?.label ?? 'n/a');

        // eslint-disable-next-line
    }, [axes]);

    /**
     * Event handlers
     */

    const handleCurriculum = (user) => {
        navigate(`/manager/motions/${motion.id}/cv/${user.id}`);
    }

    return (

        <div className='container'>
            <div className='row pt-2'>

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th className='text-center'>{t('motions.code')}</th>
                            <th>{t('users.email')}</th>
                            <th>{t('glossary.company')}</th>
                            <th className='text-center'>{t('motions.score')}</th>
                            <th className='text-center'>{t('motions.like')}</th>
                            <th className='text-center'>{xlabel}</th>
                            <th className='text-center'>{ylabel}</th>
                            <th className='text-center'>{zlabel}</th>
                            <th className='text-center'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            matchings.map((user) => {

                                const score = user.distance ?? 0;
                                const xaxis = user.distances.get(axes.x) ?? 0;
                                const yaxis = user.distances.get(axes.y) ?? 0;
                                const zaxis = user.distances.get(axes.z) ?? 0;

                                return (
                                    <tr key={user.id} className='align-middle'>

                                        <td className='text-center'>{user.label}</td>
                                        <td>{user.email}</td>
                                        <td>{user.company?.name}</td>
                                        <td className='text-center'>{score.toFixed(1)}</td>
                                        <td className='text-center'>{user.like ? <img src={star_img} className='skill-icon' alt='like' /> : '-'}</td>
                                        <td className='text-center'>{xaxis.toFixed(1)}</td>
                                        <td className='text-center'>{yaxis.toFixed(1)}</td>
                                        <td className='text-center'>{zaxis.toFixed(1)}</td>

                                        <td className='text-center'>
                                            <button className='btn btn-light' onClick={() => handleCurriculum(user)}>
                                                <img src={cv_img} alt='curriculum' />
                                            </button>
                                        </td>

                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default MatchingTable
